import React from 'react';
import './App.css';
import LandingPage from './Pages/LandingPage';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    < >
      <Toaster />
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />}></Route>
          <Route path="/home" element={<LandingPage />}></Route>
        </Routes>
      </Router>

    </>
  );
}

export default App;
