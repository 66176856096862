import React, { Suspense, useEffect, useState } from 'react'
import { env } from '../config'
import axios from 'axios'
import Loader from './Components/Loader'
const Navbar = React.lazy(() => import('./Components/Navbar'));
const Footer = React.lazy(() => import('./Components/Footer'));
const LandingPageintro = React.lazy(() => import('./Components/LandingPageintro'));
const LandingPageOfferings = React.lazy(() => import('./Components/LandingPageOfferings'));
const LandingPageCards = React.lazy(() => import('./Components/LandingPageCards'));
const LandingPageJourneySection = React.lazy(() => import('./Components/LandingPageJourneySection'));
const LandingPageGetInTouchSection = React.lazy(() => import('./Components/LandingPageGetInTouchSection'));







function LandingPage() {
    const [landingPageData, setLandingPageData] = useState<any>([])
    const url = "/api/landing-page?populate=deep"

    const fetchApiData = () => {
        axios.get(`${env}${url}`)
            .then(function (response: { data: { data: { attributes: any } } }) {
                setLandingPageData(response.data.data.attributes)
            })
            .catch((error: any) => {
            });
    }


    useEffect(() => {
        if (landingPageData?.length === 0) {
            fetchApiData()

        }

    }, [])

    // const [backgroundLoaded, setBackgroundLoaded] = useState(false);



    // const [backgroundImage, setBackgroundImage] = useState(new Image())

    // useEffect(() => {
    //     let copyBackgroundImage = { ...backgroundImage }

    //     copyBackgroundImage.src = `${env}${landingPageData?.Intro?.image?.data?.attributes?.url}`
    //     console.log("backgroundImage?.src", copyBackgroundImage?.src)

    //     if (!backgroundImage) {
    //         copyBackgroundImage.onload = () => {
    //             setBackgroundLoaded(true);
    //             setBackgroundImage(copyBackgroundImage)

    //         };
    //     }


    //     copyBackgroundImage.onerror = (err) => {
    //         console.error('Error loading image', err);
    //     };

    //     return () => {
    //         backgroundImage.onload = null;
    //         backgroundImage.onerror = null;
    //     };
    // }, [landingPageData, backgroundImage])



    return (
        <Suspense fallback={<Loader />}>
            <Navbar logo={landingPageData?.logo} />
            <LandingPageintro intro={landingPageData?.Intro} />
            <LandingPageOfferings offering={landingPageData?.offering} />
            <LandingPageCards partnership={landingPageData?.partnership} />
            <LandingPageJourneySection journeySection={landingPageData?.journeySection} />
            <LandingPageGetInTouchSection getInTouch={landingPageData?.getInTouch} />
            <Footer />
        </Suspense >




    )
}

export default LandingPage